import React from "react"
import Img from "gatsby-image"

// *** COLOUR WORDS ***
/* splits string, and colours each word with as many given */
export const colorWords = string => {
  const colors = [`#fc4a1a`, `#fcbb44`, `#516ff1`]
  let words = string.split(" ")
  words.length = 3
  let i = 0
  words = words.map(word => {
    const color = { color: colors[i] }
    const res = (
      <span style={color} key={word + color}>
        {" "}
        {word}
      </span>
    )
    i++
    return res
  })
  return words
}

export const splitWords = string => {
  let words = string.split(" ")
  words = words.map(word => {
    return <span key={string + word}> {word}</span>
  })
  return words
}

// *** RETURN CONTENT ***
/*return content from wordpress in corrent language */
export const getTitle = (titles, language) => {
  return titles ? (language === "mt" ? titles.title_mt : titles.title) : null
}
export const getWordsToAnimate = (titles, language) => {
  const flattenedResult = []
  const wordsArray = titles
    ? language === "mt"
      ? titles.animated_words_mt
      : titles.animated_words
    : null

  wordsArray.map(word => flattenedResult.push(word.words))
  return flattenedResult
}
export const getSubtitle = (subtitles, language) => {
  return subtitles
    ? language === "mt"
      ? subtitles.subtitle_mt
      : subtitles.subtitle
    : null
}
export const getContent = (contents, language) => {
  return contents
    ? language === "mt"
      ? contents.content_mt
      : contents.content
    : null
}
export const getIcon = (iconObject, styles, altText, alignment) => {
  const icon = iconObject ? iconObject : null
  const iconStyle = alignment
    ? alignment.toLowerCase() === "top"
      ? `${styles.icon} ${styles.top}`
      : `${styles.icon} ${styles.bottom}`
    : styles.icon
  return icon ? (
    <Img
      alt={altText ? `${altText}` : `icon`}
      className={iconStyle}
      fluid={iconObject.localFile.childImageSharp.fluid}
    />
  ) : null
}
export const getImage = (imageObject, styles, altText, alignment) => {
  const image = imageObject ? imageObject : null
  // console.log("functions alt text", altText)

  const imageStyle = alignment
    ? alignment.toLowerCase() === "right"
      ? `${styles.image} ${styles.right}`
      : `${styles.image} ${styles.left}`
    : styles.image

  return image ? (
    <Img
      alt={altText ? `${altText}` : `image`}
      className={imageStyle}
      fluid={imageObject.localFile.childImageSharp.fluid}
    />
  ) : null
}
// *** END RETURN CONTENT ***

//HEADER IMAGE
export const splitSubtitle = (subtitle, styles) => {
  const res = subtitle.split("?")
  return (
    <div className={styles.subtitleRows}>
      <p>{res[0]}?</p>
      <p>{res[1]}</p>
    </div>
  )
}

//CONTENT BLOCKS
export const setContentBlocksStyles = (uri, styles) => {
  switch (uri) {
    case "/":
      return `${styles.contentBlocks} ${styles.home}`
    case "/who-we-are":
      return `${styles.contentBlocks} ${styles.who} ${styles.page}`
    case "/what-we-do":
      return `${styles.contentBlocks} ${styles.what} ${styles.page}`
    case "/how-we-help":
      return `${styles.contentBlocks} ${styles.how} ${styles.page}`
    case "/contact":
      return `${styles.contentBlocks} ${styles.contact} ${styles.page}`
    default:
      return `${styles.contentBlocks} ${styles.page}`
  }
}

export const setBlockStyle = (image, icon, styles) => {
  const imgAlign = image.align_image ? image.align_image.toLowerCase() : null
  const iconAlign = icon.align_icon ? icon.align_icon.toLowerCase() : null

  const style = imgAlign
    ? imgAlign === "right"
      ? `${styles.block} ${styles.imageIsRight}`
      : `${styles.block} ${styles.imageIsLeft}`
    : `${styles.block} ${styles.noImage}`

  const iconStyle = iconAlign
    ? iconAlign === "top"
      ? `${styles.iconIsTop}`
      : `${styles.iconIsBottom}`
    : null

  return style + ` ` + iconStyle
}

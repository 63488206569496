import React, { Component } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import { getImage } from "../../helpers/functions"
import styles from "./header.module.scss"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
    }
  }
  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    })
  }
  closeMenu = () => {
    this.setState({
      showMenu: false,
    })
  }
  createMenuLink = (link, pages, language) => {
    const currLink = pages.filter(page => {
      return page.node.link === link.link
    })[0].node
    if (currLink.slug === `home`) {
      currLink.slug = ``
    }
    return (
      <li key={currLink.slug} className={styles.links}>
        <Link to={`/${currLink.slug}`} onClick={this.closeMenu}>
          {language === "mt" ? link.link_mt : link.link_en}
        </Link>
      </li>
    )
  }

  render() {
    const data = this.props.data
    const pages = data.allWordpressPage.edges
    const options = this.props.data.wordpressAcfOptions.options
    const language = this.props.language

    const menuLinks = options.menu_links
    const menuOpenOrClosed = this.state.showMenu
      ? `${styles.header} ${styles.open}`
      : styles.header

    const flagEn = options.flag_en.localFile.childImageSharp.fluid
    const flagMt = options.flag_mt.localFile.childImageSharp.fluid

    return (
      <header>
        <div className={menuOpenOrClosed}>
          <FontAwesomeIcon
            onClick={this.toggleMenu}
            className={styles.menuIcon}
            icon={faBars}
          />

          <Link
            to={"/"}
            onClick={this.closeMenu}
            className={`${styles.logo} wow pulse`}
          >
            {getImage(options.logo_mobile, styles, "Logo")}
          </Link>

          <ul className={styles.menuLinks}>
            {menuLinks.map(link => this.createMenuLink(link, pages, language))}
            <li className={styles.flags}>
              <div>
              <button 
                onClick={() => {
                this.props.setToEnglish()
                this.closeMenu()
              }}>
                <Img 
                  className={styles.flag}
                  fluid={flagEn}
                  alt={"English Flag"}
                />
              </button>
              </div>
              <div>
                <button 
                  onClick={() => {
                  this.props.setToMaltese()
                  this.closeMenu()
                }}>
                <Img
                  className={styles.flag}
                  fluid={flagMt}
                  alt={"Maltese Flag"}
                />
                </button>
              </div>
            </li>
          </ul>
        </div>
      </header>
    )
  }
}

export default Header

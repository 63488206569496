import React from "react"
import { colorWords, getTitle, getImage } from "../../helpers/functions"
import Copyright from "./Copyright/copyright"
import styles from "./footer.module.scss"

function Footer(props) {
  const data = props.data
  const contactPage = data.contactPage
  const options = props.data.wordpressAcfOptions.options
  const language = props.language
  const { email, telephone, address, headline_title } = contactPage.acf
  const title = getTitle(headline_title, language)

  return (
    <footer>
      {data.uri !== "/contact" && (
        <div>
          <div className={styles.footer}>
            <div className={styles.backgroundImg}>
              {getImage(
                options.footer_bg_image,
                styles,
                "Background Shape White"
              )}
            </div>
            <div className={styles.background}></div>

            <div className={styles.wrapper}>
              <div className={`${styles.left} wow fadeInLeft`}>
                {getImage(options.footer_image, styles, "Doggy in a cart")}
              </div>

              <div className={`${styles.right} wow fadeInRight`}>
                <h2 className={styles.title}>{colorWords(title)}</h2>
                <div className={styles.contactInfo}>
                  <p className={styles.telephone}>
                    <a href={`tel:${telephone}`}>{telephone}</a>
                  </p>
                  <p className={styles.email}>
                    <a href={`mailto:${email}`}>{email}</a>
                  </p>
                  <p>
                    {address.house_number} {address.street} {address.town}
                  </p>
                  <p>{address.country}</p>
                </div>

                <div className={styles.socialLinks}>
                  {options.links &&
                    options.links.map(link => (
                      <div className={styles.social} key={link.link_text}>
                        <a
                          href={link.link_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {getImage(link.icon, styles, link.link_text)}
                        </a>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <Copyright data={props.data.contactPage.acf} />
        </div>
      )}
    </footer>
  )
}

export default Footer

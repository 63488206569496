import React from "react"
import styles from "./copyright.module.scss"

function Copyright(props) {
  const { telephone, email } = props.data
  return (
    <div className={`${styles.copyright} wow fadeIn`}>
      <b>©{new Date().getFullYear()} Copyright New Directions Malta </b>
      {telephone && (
        <span>
          | <a href={`tel:${telephone}`}>{telephone} </a>
        </span>
      )}
      {email && (
        <span>
          | <a href={`mailto:${email}`}>{email} </a>
        </span>
      )}
    </div>
  )
}

export default Copyright

import "../../styles/helpers/animate.css"

import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"

import Footer from "../Footer/footer"
import Header from "../Header/header"
import SEO from "../seo"
import WOW from "wow.js"
import styles from "./layout.module.scss"

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      language: "en",
    }
  }

  setToEnglish = () => {
    if (this.state.language === "mt") {
      this.setState({ language: "en" })
      console.log(this.state)
    }
  }
  setToMaltese = () => {
    if (this.state.language === "en") {
      this.setState({ language: "mt" })
      console.log(this.state)

    }
  }
  componentDidMount = () => {
    const wow = new WOW({
      offset: 25,
    })
    wow.init()
  }

  render() {
    const props = this.props
    const uri = props.uri
    const pageTitle = props.data.wordpressPage.title
    const children = React.Children.map(props.children, child => {
      return React.cloneElement(child, {
        language: this.state.language,
        uri,
      })
    })

    return (
      <StaticQuery
        query={layoutQuery}
        render={data => (
          <div className={styles.layout}>
            <SEO title={pageTitle} lang={this.state.language} />

            <Header
              data={{ ...data, uri: uri }}
              className={styles.header}
              language={this.state.language}
              setToEnglish={this.setToEnglish}
              setToMaltese={this.setToMaltese}
            />
            <main>{children}</main>

            <Footer
              data={{ ...data, uri: uri }}
              language={this.state.language}
            />
          </div>
        )}
      />
    )
  }
}

export default Layout

export const layoutQuery = graphql`
  query layoutQuery {
    allWordpressPage {
      edges {
        node {
          slug
          link
        }
      }
    }
    contactPage: wordpressPage(slug: { eq: "contact" }) {
      slug
      acf {
        email
        telephone
        address {
          house_number
          street
          town
          country
        }
        headline_title {
          title
          title_mt
        }
      }
    }
    wordpressAcfOptions {
      options {
        links {
          link_text
          link_url
          icon {
            localFile {
              childImageSharp {
                fluid(maxWidth: 50) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
        menu_links {
          link
          link_en
          link_mt
        }
        logo_mobile {
          localFile {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        footer_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        footer_bg_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        flag_en {
          localFile {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        flag_mt {
          localFile {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"New Directions, Investing in the People of Malta and Gozo","short_name":"New Directions","start_url":"/","background_color":"#4e6bab","theme_color":"#4e6bab","display":"minimal-ui","icon":"src/images/new-directions-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bb091b27e449c5441515e1cd155a1117"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
